@font-face {
  font-family: "Space Grotesk";
  src: url("../../Assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

.app-top-nav-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4.5vw;
  background: #000000;
  border-bottom: 0.1vw solid #fff;
  border-top: 0.2vw solid #F5D42F;
  position: fixed;
  top: 0;
  z-index: 999;
}

.logo {
  display: flex;
  align-items: center;
  padding-left: 1vw;
}
.logo:hover{
  cursor: pointer;
}
.logo img {
  height: 8vw;
  width: 10vw;
}

.links {
  padding-right: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links a {
  margin-left: 2vw;
  text-decoration: none;
  color: #a0a0a0;
  font-family: "Space Grotesk" !important;
  font-size: 1.15vw;
}

.links a:hover {
  color: #fff;
}

.links a.active {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .app-top-nav-wrapper {
    align-items: center;
    height: 12vw;
    border-top: 0.9vw solid #f5d42f;
  }

  .logo {
    order: 2;
   justify-content: center;
   margin: 0 auto;
  }
  .logo img{
    height: 21vw;
    width: unset;
  }
  .menu-icon {
    order: 1;
    display: block;
    cursor: pointer;
    color: #fff;
    position: absolute;
    right: 4vw;
    top: 2vw;
    font-size: 7vw;
  }

  .links {
    order: 3;
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 10vw;
  }

  .links a {
    text-align: center;
    padding: 1vw;
    border-top: 1px solid gray;
    background: #000;
    width: 100%;
    font-size: 3vw;
    height: 6vw;
  }

  .links.open {
    display: flex;
  }
}