@font-face {
  font-family: "Space Grotesk";
  src: url("../../Assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../../Assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Space Grotesk";
  src: url("../../Assets/fonts/SpaceGrotesk-Light.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

.app-content {
  margin-top: 4.5vw;
}

.app-home-wrapper {
  background: linear-gradient(to bottom, #000 40%, #181818 40%);
  height: 110vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.top-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 35vw;
  padding-top: 5vw;
}

.top-header-content h3 {
  font-family: "Space Grotesk" !important;
  font-size: 1.3vw;
  margin: 0 !important;
  letter-spacing: 0.4vw;
  color: #fff;
}

.top-header-content p.header-content-3 {
  font-family: "Space Grotesk" !important;
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 1vw;
  letter-spacing: 0.2vw;
  line-height: 2vw;
}

.header-action-btn {
  margin-right: 1vw;
  border: none;
  width: 13vw;
  height: 3vw;
  font-weight: 700;
  font-size: 1vw;
  font-family: "Space Grotesk" !important;
  letter-spacing: 0.2vw;
}

.header-action-btn:hover {
  cursor: pointer;
}

.signup {
  background-color: #F5D42F;

  &:hover {
    color: #000;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
      linear-gradient(135deg, #F5D42F 0%, #F5D42F 50%, #F5D42F 50%, #F5D42F 100%);
    box-shadow: 0 0 0.8vw #F5D42F;
  }
}

.signup svg {
  font-size: 1vw;
}

.contact {
  background-color: #000;
  background: lighten($color: #000000, $amount: 3%);
  border: 0.1vw solid #F5D42F;
  color: #fff !important;

  &:hover {
    color: #fff;
    background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
      linear-gradient(135deg, #000 0%, #000 50%, #000 50%, #000 100%);
    box-shadow: 0 0 0.8vw #F5D42F;
  }
}

.contact svg {
  font-size: 0.8vw;
}

.video-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.home-footer-wrapper {
  padding-top: 5vw;
  height: 45vw;
  width: 65vw;
}

.home-footer-content {
  height: 100%;
  display: flex;
}

.home-footer-content img {
  height: 35vw;
  width: 25vw;
  object-fit: cover;
}

.home-footer-content img {
  opacity: 0;
  transition: opacity 1s;
}

.fade-in-left {
  opacity: 1 !important;
  transform: translateX(0);
}


.header-video-player {
  height: 30vw !important;
  padding-top: 8vw !important;
  width: 65vw !important;
  object-fit: cover;
}

.header-video-player video {
  width: 65vw;
  height: 100%;
  object-fit: cover !important;
}

.footer-right-content {
  display: flex;
  padding-left: 7vw;
  flex-direction: column;
  font-family: "Space Grotesk" !important;
  color: #fff;
  padding-top: 2.5vw;

  h3 {
    font-size: 1.1vw;
    margin: 0;
    letter-spacing: 0.3vw;

  }

  .para-one {
    font-size: 2.5vw;
    margin: 2vw 0 0 0;
    letter-spacing: 0.08vw;
    text-align: justify;
  }

  .para-two {
    font-weight: 100;
    line-height: 2vw;
    font-size: 1.1vw;
    letter-spacing: 0.05vw;
    text-align: justify;
  }

  ul {
    font-weight: 100;
    font-size: 1.1vw;
    color: #F5D42F;
    list-style-position: inside;
    padding-left: 0 !important;
    margin-left: 0;

    li span {
      color: #fff;
    }

    li {
      letter-spacing: 0.08vw;
      line-height: 2vw;
    }
  }

  button {
    margin-top: 2vw;
  }
}

.parent-wrapper {
  background-color: black;
  height: 70vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .content-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 2vw;
    height: 15vw;

    .alignMiddle {
      width: 65vw;
      display: flex;
      justify-content: space-between;

      .container {
        position: relative;
        display: inline-block;
        width: 25vw;

        img {
          display: block;
          width: 10vw;
        }

        .content {
          position: absolute;
          width: 100%;
          top: 80%;
          text-align: start;
          transform: translateY(-25%) translateX(10%);
          font-family: "Space Grotesk" !important;
          font-weight: 400;

          h3 {
            color: #fff;
            width: 80%;
            line-height: 2vw;
            font-size: 1.3vw;
            letter-spacing: 0.1vw;
          }

          p {
            color: #fff;
            font-weight: 100;
            line-height: 1.7vw;
            font-size: 1vw;
            width: 80%;
            letter-spacing: 0.01vw;
            text-align: justify;
          }

          label {
            color: #F5D42F;
            letter-spacing: 0.2vw;
            font-size: 1vw;
          }
        }
      }

    }

  }

  .section-name {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 15vw;
    flex-direction: column;

    h3 {
      font-family: "Space Grotesk" !important;
      font-size: 1.3vw;
      margin: 0 !important;
      letter-spacing: 0.4vw;
      color: #fff;
    }

    p.header-content-3 {
      font-family: "Space Grotesk" !important;
      color: #fff;
      text-align: center;
      font-weight: 400;
      font-size: 1vw;
      letter-spacing: 0.2vw;
      line-height: 2vw;
    }

  }

  .grid-wrapper {
    width: 65vw;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1vw;
    margin-top: 3vw;

    .box {
      border: 0.1vw solid #5a5a5a;
      padding: 1.5vw;
      height: 16vw;
      text-align: start;
      transition: transform 0.5s ease;

      .iconContainer {
        width: 2.5vw;
        height: 2.5vw;
        background-color: #F5D42F;
        border-radius: 2.5vw;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 2vw;
          height: 1.3vw;
        }
      }

      h3 {
        color: #fff;
        font-family: "Space Grotesk" !important;
        letter-spacing: 0.1vw;
        font-size: 1.3vw;
      }

      p {
        color: #fff;
        font-family: "Space Grotesk" !important;
        font-weight: 300;
        line-height: 1.5vw;
        font-size: 0.9vw;
      }

      label {
        color: #F5D42F;
        letter-spacing: 0.2vw;
        font-size: 0.8vw;
        font-family: "Space Grotesk" !important;
      }
    }

    .box:hover {
      transform: translateY(-1vw);
      animation-delay: 110ms;
    }
  }

}

.component-wrap {
  background-color: #F5D42F;
  height: 49vw;
  display: flex;

  .child {
    width: 50%;
    height: 100%;

    .child-content {
      padding-left: 17vw;
      padding-top: 4vw;
      padding-right: 2vw;

      h4 {
        font-family: "Space Grotesk" !important;
        font-size: 1vw;
        letter-spacing: 0.15vw;
        font-weight: 700;
      }

      h3 {
        font-family: "Space Grotesk" !important;
        font-size: 2vw;
        letter-spacing: 0.1vw;
        line-height: 1.5;
      }

      .list {
        display: flex;
        margin-bottom: 1.3vw;

        .ico-wrap {
          width: 3vw;
          height: 3vw;
          background-color: #000;
          border-radius: 3vw;
          margin-right: 1.1vw;
          padding-right: 2vw;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            margin: 0 auto;
            color: white;
            margin-right: -1.2vw;
            width: 1.5vw;
            height: 1.5vw;
          }
        }

        .list-content {
          h4 {
            font-family: "Space Grotesk" !important;
            margin-top: 1vw;
            font-size: 1.4vw;
            letter-spacing: 0;
            margin-bottom: 0.5vw;
          }

          p {
            font-family: "Space Grotesk" !important;
            font-size: 1vw;
            font-weight: 300;
            margin-top: 0;
          }
        }
      }
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
}

.footerComponent {
  height: 36vw;
  background-color: #000;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .content-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 65vw;
    padding-top: 1vw;
    padding-left: 17vw;

    h4 {
      font-family: "Space Grotesk" !important;
      color: #fff;
      font-size: 1vw;
      letter-spacing: 0.2vw;
      font-weight: 600;
    }

    .flexDiv {
      display: flex;
      justify-content: space-between;
      align-items: center;

      h2 {
        font-family: "Space Grotesk" !important;
        color: #fff;
        margin-top: 0;
        font-size: 2.3vw;
      }

      .actionBtn {
        margin-right: 1vw;
        border: none;
        width: 13vw;
        height: 3vw;
        font-weight: 700;
        font-size: 1vw;
        font-family: "Space Grotesk" !important;
        letter-spacing: 0.2vw;
      }

      .actionBtn:hover {
        cursor: pointer;
      }

      .signup {
        background-color: #F5D42F;

        &:hover {
          color: #000;
          background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
            linear-gradient(135deg, #F5D42F 0%, #F5D42F 50%, #F5D42F 50%, #F5D42F 100%);
          box-shadow: 0 0 0.8vw #F5D42F;
        }
      }

      .signup svg {
        font-size: 1vw;
      }

      .contact {
        background-color: #000;
        background: lighten($color: #000000, $amount: 3%);
        border: 0.1vw solid #F5D42F;
        color: #fff !important;

        &:hover {
          color: #fff;
          background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
            linear-gradient(135deg, #000 0%, #000 50%, #000 50%, #000 100%);
          box-shadow: 0 0 0.8vw #F5D42F;
        }
      }

      .contact svg {
        font-size: 0.8vw;
      }
    }

    .ftrSocial {
      display: flex;
      padding-top: 4vw;
      justify-content: space-between;

      .logoContainer {
        display: flex;

        justify-content: start;
        flex-direction: column;

        img {
          width: 12vw;
          height: 12vw;
        }

        h3 {
          font-family: "Space Grotesk" !important;
          color: #fff;
          margin-top: 0;
          font-size: 1.1vw;
          font-weight: 300;
          letter-spacing: 0.2vw;

        }

        .socialMedia {
          display: flex;

          .icon {
            border: 0.1vw solid #F5D42F;
            width: 2.3vw;
            height: 2.3vw;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1vw;

            svg {
              width: 1.5vw;
              height: 1.5vw;
              color: #fff;
            }

            svg:hover {
              cursor: pointer;
            }
          }

          .icon:hover {
            cursor: pointer;

            svg {
              color: #f5d42f;
            }
          }

          .fbicon {
            margin-left: 0vw;
          }
        }
      }

      .downloadBtn {
        display: flex;
        flex-direction: column;
        padding-top: 4vw;
        justify-content: space-between;
        .playStore {
          width: 10vw;
          height: 4vw;
        }

        .deleteAccnt {
          color: #F5D42F;
          margin: 0;
          padding-top: 0.5vw;
          font-family: 'Space Grotesk';
          font-size: 0.8vw;
          font-weight: 300;
          text-decoration: underline;
          letter-spacing: 0.1vw;
          padding-left: 0.6vw;
          height: 1.8vw;
          width: 8vw;
          margin-left: 0.8vw;
          border-radius: 0.2vw;
        }

        .playStore:hover,
        .appStore:hover,.deleteAccnt:hover{
          cursor: pointer;
        }

        .appStore {
          object-fit: cover;
          width: 10vw;
        }
      }
    }
  }

  .footer {
    background-color: #181818;
    height: 3vw;

    .content {
      width: 65vw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto;
      height: 100%;

      h3 {
        margin: 0;
        color: #fff;
        font-family: 'Space Grotesk';
        font-size: 1vw;
        font-weight: 300;
        letter-spacing: 0.05vw;
      }

      div {
        display: flex;

        a {
          text-decoration: none;
        }

        h3:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.service-wrap {
  background-color: #181818;
  height: 45vw;

  .center-content {
    display: flex;
    justify-content: space-between;
    width: 65vw;
    align-items: start;
    padding-top: 4vw;
    margin: 0 auto;

    h3 {
      font-size: 1.2vw;
      letter-spacing: 0.3vw;
      font-weight: 400;
      margin-bottom: 0;
      color: #fff;
      font-family: "Space Grotesk" !important;
      padding-left: 0.6vw;
    }

    .section-two {
      max-width: 30vw;

      .one,
      .two,
      .three {
        display: flex;
        margin-top: 3vw;
        margin-bottom: 3vw;

        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 6vw;
          margin-right: 1vw;

          h1 {
            font-family: "Space Grotesk" !important;
            color: #F5D42F;
            letter-spacing: 0.3vw;
            font-size: 4vw;
            margin-top: 0;
          }
        }

        div {
          display: flex;
          flex-direction: column;
          padding-top: 1.1vw;

          h2 {
            margin: 0;
            color: #fff;
            font-family: 'Space Grotesk';
            font-size: 1.6vw;
            letter-spacing: 0.1vw;
            font-weight: 400;
          }

          p {
            font-size: 1.1vw;
            font-family: 'Space Grotesk';
            color: #fff;
            font-weight: 200;
            max-width: 23vw;
            word-wrap: break-word;
            // text-align: justify;
          }
        }
      }

      .one,
      .two {
        border-bottom: 0.1vw solid #fff;
      }
    }

    .section-three {
      height: 35vw;
      width: 20vw;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.contactForm {
  background-color: #F5D42F;
  height: 40vw;

  .center-content {
    display: flex;
    justify-content: space-between;
    width: 65vw;
    align-items: start;
    padding-top: 4vw;
    margin: 0 auto;

    .left-content {
      h3 {
        font-size: 1.3vw;
        font-family: 'Space Grotesk';
        letter-spacing: 0.2vw;
        font-weight: 600;
      }

      h2 {
        font-size: 2.4vw;
        font-family: 'Space Grotesk';
        letter-spacing: 0.1vw;
      }

      .contactWrap {
        display: flex;
        padding-top: 3vw;

        .email,
        .phone {
          display: flex;

          .circle {
            width: 3vw;
            height: 3vw;
            border-radius: 50%;
            background-color: #000;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              color: #F8D730;
              width: 1.6vw;
              height: 1.6vw;
            }
          }

          .info {
            padding-left: 0.5vw;
            padding-top: 0.6vw;

            h3 {
              margin-top: 0;
              margin-bottom: 0;
              letter-spacing: 0;
              font-family: 'Space Grotesk';
            }

            h4 {
              font-size: 1vw;
              font-weight: 300;
              letter-spacing: 0;
              padding-top: 1vw;
              margin-top: 0;
              margin-bottom: 0;
              font-family: 'Space Grotesk';
            }

          }
        }
      }
    }
  }
}

.form-wrapper {
  width: 36vw;

  .formStatus {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: 'Space Grotesk';
    height: 30vw;
    padding-left: 5vw;

    svg {
      height: 5vw;
      width: 5vw;
    }

    h3 {
      font-size: 2.5vw;
      line-height: 4vw;
      text-align: center;
      letter-spacing: 0.2vw;
    }
  }

  .nameField {
    display: flex;
    justify-content: space-between;
    margin-top: 1.5vw;
    margin-bottom: 1vw;
  }

  .send {
    background-color: #000;
    background: lighten($color: #000000, $amount: 3%);
    border: 0.1vw solid #F5D42F;
    color: #f5d42f !important;
    font-size: 1vw;
    font-family: 'Space Grotesk';
    width: 100%;
    height: 3.5vw;
    letter-spacing: 0.2vw;

    &:hover {
      color: #fff;
      background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
        linear-gradient(135deg, #000 0%, #000 50%, #000 50%, #000 100%);
      box-shadow: 0 0 0.8vw #F5D42F;
      cursor: pointer;
    }
  }
}

.firstName {
  width: 49%;
  margin-right: 1vw;
  max-height: 4.2vw;
}

.lastName {
  width: 49%;
  max-height: 4.2vw;
}

.email {
  max-height: 4.2vw;
}

.firstName,
.lastName,
.email {
  div {
    height: 4.2vw;
    max-height: 4.2vw;
  }
}

.firstName,
.lastName,
.email,
.feedback {
  p[class^="MuiFormHelperText-root Mui-error"] {
    font-size: 0.7vw;
    font-family: 'Space Grotesk';
    margin: 0;
  }

  label {
    font-family: 'Space Grotesk';
    font-size: 1.3vw;
  }

  div {
    border-radius: 0vw;

    input {
      font-family: 'Space Grotesk';
      font-size: 1.2vw;
    }
  }

  div:focus {
    outline: none;
    border: none;
  }
}

.email,
.feedback {
  width: 100%;
  margin-bottom: 1vw !important;
}

.feedback div {
  height: 15vw;
  padding: 1vw;

  textarea {
    font-family: 'Space Grotesk';
    font-size: 1.3vw;
    overflow-y: auto !important;
    height: 100% !important;
  }
}

.articles-wrapper {
  background-color: #000;
  height: 36vw;

  .title {
    margin: 0 auto;
    text-align: center;
    padding-top: 2vw;

    h3 {
      margin: 0;
      color: #fff;
      font-size: 1.3vw;
      font-family: 'Space Grotesk';
    }
  }

  .articles {

    display: flex;
    justify-content: space-between;
    height: 25vw;
    padding: 3vw;

    .post {
      height: 100%;
      width: 20%;
      border: 0.1vw solid #5a5a5a;
      overflow: hidden;

      .imageContainer {
        overflow: hidden;
        width: 100%;
        height: 75%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 1s ease;

      }

      img:hover {
        transform: scale(1.2);
      }

      .postTitle {
        padding-left: 0.8vw;
        padding-top: 0.3vw;

        h3 {
          margin: 0;
          color: #fff;
          font-family: 'Space Grotesk';
          font-size: 1.1vw;
          letter-spacing: 0.1vw;
          font-weight: 400;
          -webkit-line-clamp: 2;
          text-overflow: ellipsis;
          -webkit-box-orient: vertical;
          display: -webkit-box;
          overflow: hidden;
        }

        h4 {
          margin: 0;
          font-family: 'Space Grotesk';
          color: #F5D42F;
          font-size: 0.9vw;
          letter-spacing: 0.1vw;
          margin-top: 0.4vw;

          svg {
            width: 1vw;
            height: 1vw;
          }
        }
      }
    }

    .post:hover {
      cursor: pointer;
    }
  }
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .modal {
    width: 80%;
    max-width: 40VW;
    background-color: #fff;
    padding: 2vw;
    height: 35vw;
    border-radius: 0.4vw;
    box-shadow: 0vw 1vw 0.8vw rgb(94 94 94 / 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 7vw;

    .formStatus {
      display: flex;
      flex-direction: column;
      align-items: center;

      svg {
        font-size: 7vw;
        color: #F5D42F;
      }

      svg.error {
        color: #d50000;
      }

      .message {
        margin-top: 2vw;
      }
    }

    h3 {
      margin: 0 auto;
      font-family: 'Space Grotesk';
      font-size: 1.4vw;
      letter-spacing: 0.1vw;
    }

    .form-container {
      padding-top: 1vw;

      div[class^="MuiGrid-root MuiGrid-item"] {
        p[class^="MuiFormHelperText-root Mui-error"] {
          font-size: 0.7vw;
          font-family: 'Space Grotesk';
          margin: 0;
        }
      }

      .signup {
        background-color: #F5D42F;
        background: lighten($color: #F5D42F, $amount: 3%);
        border: 0.1vw solid #F5D42F;
        color: #000 !important;
        font-size: 1vw;
        font-weight: 600;
        font-family: 'Space Grotesk';
        width: 100%;
        height: 3.5vw;
        letter-spacing: 0.2vw;

        &:hover {
          color: #000;
          background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
            linear-gradient(135deg, #F5D42F 0%, #F5D42F 50%, #F5D42F 50%, #F5D42F 100%);
          box-shadow: 0 0 0.8vw #F5D42F;
          cursor: pointer;
        }
      }

      .pswd {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 1.5vw;
      }

      .fullName,
      .email,
      .phone,
      .gender {
        label {
          font-family: 'Space Grotesk';
          font-size: 1.2vw;
          line-height: 2.2vw;
          letter-spacing: 0.1vw;
          max-width: calc(100% - 2vw);
          ;

          span {
            font-family: 'Space Grotesk';
            font-size: 1.2vw;
          }
        }

        div {
          border-radius: 0;

          input {
            font-family: 'Space Grotesk';
            font-size: 1.1vw;
            padding: 1.2vw 1.1vw;
          }
        }

        p[class^="MuiFormHelperText-root Mui-error"] {
          font-size: 0.7vw;
          font-family: 'Space Grotesk';
          margin: 0;
        }
      }

      .email,
      .phone {
        margin-top: 0.5vw;
      }

      .item {
        padding-left: 1.2vw;
        padding-top: 1.2vw;
        height: 6vw;
        max-height: 6vw;

        div[class$="-MuiButtonBase-root-MuiCheckbox-root"] {
          padding: 0.4vw;
        }

        svg {
          width: 1.4vw;
          height: 1.4vw;
        }

        div label {
          font-family: 'Space Grotesk';
          font-size: 1.2vw;

          span {
            font-family: 'Space Grotesk';
            font-size: 1.2vw;
          }

          .terms {
            text-decoration: underline;
            cursor: pointer;

            a {
              color: #000;
            }
          }

          p[class^="MuiFormHelperText-root Mui-error"] {
            font-size: 0.7vw;
            font-family: 'Space Grotesk';
            margin: 0;
          }
        }

        .confirmPswd {
          div {
            border-radius: 0;

            input {
              padding: 1.2vw 1.1vw;
              font-family: "Space Grotesk";
              font-size: 1.1vw;
            }
          }

          p[class^="MuiFormHelperText-root Mui-error"] {
            font-size: 0.7vw;
            font-family: 'Space Grotesk';
            margin: 0;
          }

        }

        .pswdControl {
          margin-left: 0;
          margin-top: 0.7vw;

          label {
            font-family: 'Space Grotesk';
            font-size: 1.2vw;
          }

          div {
            border-radius: 0;
            padding-right: 0.8vw;

            #outlined-adornment-password {
              padding: 1.2vw 1.1vw;
              font-family: "Space Grotesk";
              font-size: 1.1vw;

            }
          }

          p[class^="MuiFormHelperText-root Mui-error"] {
            font-size: 0.7vw;
            font-family: 'Space Grotesk';
            margin: 0;
          }
        }
      }

      .gender {
        p[class^="MuiFormHelperText-root Mui-error"] {
          margin-top: -0.6vw !important;
        }
      }
    }
  }
}

:global {

  div[class$="-MuiInputBase-root-MuiOutlinedInput-root"] {
    border-radius: 0vw !important;
  }

  div[class$="Mui-focused MuiOutlinedInput-notchedOutline"] {
    border-color: #000000 !important;
    border-width: 0.1vw !important;
  }

  div[class$="-MuiGrid-root"] {
    margin-top: -1.2vw;
    margin-left: -1.2vw;

  }

  svg[class^="MuiSvgIcon-root "] {
    width: 1.5vw;
    height: 1.5vw;
    font-size: 1.2vw;
  }

  [class$="MuiFormHelperText-root"] {
    font-size: 0.7vw;
    font-family: 'Space Grotesk';
  }

}

@media (max-width: 768px) {
  .app-home-wrapper {
    height: 148vw;

    .top-header-content {
      width: 65vw;
      padding-top: 0vw;

      h3 {
        font-size: 3vw;
        letter-spacing: 0.5vw;
      }

      p {
        font-size: 2vw;
        letter-spacing: 0.5vw;
        line-height: 4vw;
      }

      .top-header-actions {

        .header-action-btn.signup,
        .header-action-btn.contact {
          width: 24vw;
          height: 6vw;
          font-size: 2vw;

          svg {
            font-size: 1.7vw;
          }
        }
      }

      .header-video-player {
        width: 85vw !important;
        height: 35vw !important;
      }
    }

    .header-video-player {
      width: 85vw !important;
      height: 35vw !important;
    }

    .home-footer-wrapper {
      width: 85vw;

      .home-footer-content {
        img {
          height: 58vw;
          width: 35vw;
        }

        .footer-right-content {
          h3 {
            font-size: 2.4vw;
          }

          .para-one {
            line-height: 4vw;
            font-size: 2.5vw;
          }

          .para-two {
            line-height: 3vw;
            font-size: 2vw;
          }

          ul {
            font-size: 1.9vw;
            list-style-position: outside;

            li {
              line-height: 4vw;
            }
          }
        }
      }
    }

  }

  .parent-wrapper {
    height: 200vw;

    .content-wrapper {
      height: 85vw;
      justify-content: start;
      padding-top: 6vw;
      width: 75vw;

      .alignMiddle {
        justify-content: space-between;
        flex-direction: column;
        align-items: center;
        height: 100%;

        .container {
          width: 70vw;

          img {
            width: 25vw;
          }

          .content {
            top: 50%;

            h3 {
              font-size: 3vw;
              letter-spacing: 0.3vw;
              line-height: 4vw;
            }

            p {
              font-size: 2.2vw;
              line-height: 3vw;
              letter-spacing: 0.08vw;
            }
          }
        }
      }
    }

    .section-name {
      padding-top: 20vw;

      h3 {
        font-size: 3vw;
      }

      p.header-content-3 {
        font-size: 2.2vw;
      }
    }

    .grid-wrapper {
      grid-template-columns: repeat(2, 1fr);

      .box {
        height: 30vw;

        .iconContainer {
          width: 5vw;
          height: 5vw;

          svg {
            width: 5vw;
            height: 3vw;
          }
        }

        h3 {
          font-size: 3vw;
        }

        p {
          font-size: 2vw;
          line-height: 2.7vw;
        }
      }
    }
  }

  .component-wrap {
    height: 100vw;

    .child {
      width: 85vw;

      .child-content {
        padding-top: 2vw;

        h4 {
          font-size: 3vw;
        }

        h3 {
          font-size: 4vw;
        }

        .list {
          justify-content: center;

          .ico-wrap {
            width: 6vw;
            height: 6vw;
            padding-right: 2vw;
            border-radius: 6vw;
            margin-right: 4vw;
            margin-top: 1vw;

            svg {
              width: 3vw;
              height: 3vw;
              margin-left: 1.6vw;
              margin-right: 0.5vw;
            }
          }

          .list-content {
            h4 {
              font-size: 3vw;
            }

            p {
              font-size: 2.6vw;
            }
          }
        }
      }
    }
  }

  .child:last-child {
    display: none;
  }

  .service-wrap {
    height: 90vw;

    .center-content {
      flex-direction: column;
      width: 100%;
      align-items: center;
      height: 100%;
      justify-content: space-between;
      padding-top: 0;

      .section-two {
        height: 60%;
        padding-top: 4vw;
        width: 80vw;
        max-width: 80vw;

        h3 {
          font-size: 3vw;
        }

        .one,
        .two,
        .three {
          span {
            margin-right: 5vw;

            h1 {
              font-size: 10vw;
            }
          }

          div {
            h2 {
              font-size: 3vw;
            }

            p {
              max-width: 70vw;
              font-size: 3vw;
            }
          }
        }
      }

      .section-three {
        display: none;
      }
    }
  }

  .articles-wrapper {
    height: 70vw;

    .title {
      padding-top: 6vw;

      h3 {
        font-size: 3vw;
      }
    }

    .articles {
      display: flex;
      overflow-x: scroll;
      align-items: center;
      column-gap: 2vw;
      -webkit-overflow-scrolling: touch;
      scroll-snap-type: x mandatory;
      height: 50vw;
      padding-top: 5vw;

      .post {
        scroll-snap-align: center;
        min-width: 40%;

        .item {
          width: 100%;
          height: 100%;
          object-fit: cover;

          .postTitle {
            padding-left: 1vw;
            padding-top: 1.5vw;

            h3 {
              font-size: 3vw;
            }

            h4 {
              font-size: 2vw;

              svg {
                width: 2vw;
                height: 2vw;
              }
            }
          }
        }
      }
    }
  }

  .contactForm {
    height: 90vw;

    .center-content {
      flex-direction: column;
      align-items: start;
      justify-content: center;

      .left-content {
        width: 100%;

        h3 {
          font-size: 3vw;
        }

        h2 {
          font-size: 3.3vw;
        }

        .contactWrap {
          padding-top: 0;

          .email,
          .phone {
            max-height: 8vw;

            .circle {
              width: 5vw;
              height: 5vw;

              svg {
                width: 2.2vw;
                height: 2.2vw;
              }
            }

            .info {
              padding-left: 1vw;

              h3 {
                font-size: 2.5vw;
              }

              h4 {
                padding-top: 0;
                font-size: 2.5vw;
              }
            }
          }

          .phone {
            margin-left: 7vw;
          }
        }
      }

      .form-wrapper {
        width: 100%;

        .formStatus {
          height: 50vw;

          svg {
            width: 10vw;
            height: 10vw;
          }

          h3 {
            font-size: 4vw;
            line-height: 5vw;
          }
        }

        .nameField {
          margin-top: 3vw;
          margin-bottom: 2.5vw;

          .firstName,
          .lastName {
            height: 8vw;
            max-height: 8vw;

            label {
              font-size: 3vw;
              width: 100%;
              text-align: center;
              left: -2.5vw;
              top: 1.5vw;
            }

            div {
              height: 8vw;
              max-height: 8vw;

              input {
                font-size: 3vw;
                height: 4.5vw;
                padding-left: 3vw;
              }

              fieldset {
                legend {
                  span {
                    font-size: 2.5vw;
                  }
                }
              }
            }
          }

          .firstName,
          .lastName {
            p[class^="MuiFormHelperText-root Mui-error"] {
              font-size: 2vw;
            }
          }
        }

        .email {
          height: 8vw;
          max-height: 8vw;

          label {
            font-size: 3vw;
            width: 100%;
            top: 1.5vw;
            left: 2.5vw;
          }

          div {
            height: 8vw;
            max-height: 8vw;

            input {
              font-size: 3vw;
              height: 4.5vw;
              padding-left: 3vw;
            }

            fieldset {
              legend {
                span {
                  font-size: 2.5vw;
                }
              }
            }
          }
        }

        .email,
        .feedback {
          p[class^="MuiFormHelperText-root Mui-error"] {
            font-size: 2vw;
          }
        }

        .feedback {
          margin-top: 2vw;

          div {
            height: 25vw;

            textarea {
              font-size: 3vw;
            }
          }
        }

        .send {
          height: 7vw;
          font-size: 3vw;
        }
      }
    }
  }

  .MuiInputLabel-shrink {
    left: 4vw !important;
    text-align: inherit !important;
    top: -1vw !important;
  }

  .footerComponent {
    height: 60vw;

    .content-wrapper {
      h4 {
        font-size: 3vw;
      }

      .flexDiv {
        flex-direction: column;
        align-items: start;

        h2 {
          font-size: 3.5vw;
        }

        .actionBtns {
          display: flex;
          align-items: center;
          justify-content: center;

          .signup {
            width: 24vw;
            height: 6vw;
            font-size: 2vw;
            text-align: center;

            svg {
              font-size: 1.7vw;
            }
          }

          a {
            align-items: center;
            display: flex;

            .contact {
              width: 18vw;
              height: 4vw;
              font-size: 1.4vw;

              span svg {
                font-size: 1.2vw;
              }
            }
          }
        }
      }

      .ftrSocial {
        padding-top: 0;
        width: 40vw;
        justify-content: flex-start;

        .logoContainer {
          img {
            width: 18vw;
            height: 15vw;
          }

          h3 {
            font-size: 2vw;
            margin-left: 1.3vw;
          }

          .socialMedia {
            .icon {
              width: 4vw;
              height: 4vw;
              margin-left: 1.5vw;

              svg {
                width: 3vw;
                height: 3vw;
              }
            }
          }
        }

        .downloadBtn {
          img {
            width: 20vw !important;
            height: 7.5vw !important;
          }
        }
      }
    }

    .footer {
      height: 5vw;

      .content {
        h3 {
          font-size: 1.5vw;
        }

        div a h3 {
          font-size: 1.5vw;
          padding-right: 2vw;
        }
      }
    }
  }

  .modal-overlay {
    .modal {
      width: 85%;
      max-width: 85%;
      height: 80vw;
      margin-top: 1vw;
      padding-top: 0vw;

      .formStatus {
        svg {
          font-size: 15vw;
        }

        .message {
          font-size: 4vw;
        }
      }

      h3 {
        font-size: 3vw;
      }

      .form-container {
        padding-top: 5vw;

        .item {
          padding-left: 16px;
        }

        .item:first-child {
          margin-bottom: 10vw;
        }

        .fullName,
        .email,
        .phone {
          height: 8vw;
          max-height: 8vw;

          label {
            font-size: 3vw !important;
            width: 100%;
            top: 1.5vw;
            left: 2.5vw;

          }

          div {
            height: 8vw;
            max-height: 8vw;

            input {
              font-size: 3vw;
              height: 4.5vw;
              padding-left: 3vw;
            }
          }
        }

        .pswd {
          margin-top: 3vw;
          width: 100%;
          align-items: flex-start;
          justify-content: flex-start;
          margin-bottom: 5vw;
          margin-right: 0;
          margin-left: 0;
          display: flex;

          .item:first-child {
            padding-top: 0vw;
            padding-left: 0;
            flex-basis: 48%;

            .pswdControl {
              height: 8vw;
              max-height: 8vw;

              label {
                font-size: 3vw !important;
                width: 100%;
                top: -3.5vw;
                left: -1.5vw;
              }

              div {
                height: 8vw;
                max-height: 8vw;

                input {
                  height: 8vw;
                  max-height: 8vw;
                  font-size: 3vw !important;
                }

                button {
                  padding-right: 3vw;

                  svg {
                    width: 3vw;
                    height: 3vw;
                  }
                }
              }
            }
          }

          .item:nth-child(2) {
            padding-top: 0.6vw;
            flex-basis: 52%;

            .confirmPswd {
              height: 8vw;
              max-height: 8vw;

              label {
                font-size: 3vw !important;
                width: 100%;
                top: 1.5vw;
                left: 2.5vw;
              }

              div {
                height: 8vw;
                max-height: 8vw;

                input {
                  height: 8vw;
                  max-height: 8vw;
                  font-size: 3vw !important;
                }
              }
            }
          }
        }

        label {
          span {

            //padding-left: 3.5vw;
            svg {
              width: 4vw !important;
              height: 4vw !important;
            }
          }
        }

        .terms {
          a {
            font-size: 3vw;
          }
        }

        .signup {
          font-size: 3vw;
          height: 7vw;
          margin-top: 8vw;
        }

        .item {
          p[class^="MuiFormHelperText-root Mui-error"] {
            font-size: 2vw !important;
          }
        }

        .gender {
          p[class^="MuiFormHelperText-root Mui-error"] {
            margin-top: -3vw !important;
          }
        }
      }

      .form-container> :nth-child(2),
      .form-container> :nth-child(3) {
        .item {
          margin-bottom: 3vw;

          .gender {
            margin-top: 2vw;

            label {
              font-size: 3vw;
            }

            div {
              padding-left: 1vw;

              label {
                font-size: 3vw;

                span {
                  font-size: 3vw;
                }

                span svg {
                  width: 3vw;
                  height: 3vw;
                }
              }
            }
          }
        }
      }

      .form-container> :nth-child(4) {
        .item {
          margin-bottom: 1vw;
        }
      }
    }
  }

  .pswdControl {
    .MuiInputLabel-shrink {
      top: 1.5vw !important;
    }
  }
}