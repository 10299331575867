@font-face {
    font-family: "Space Grotesk";
    src: url("../../Assets/fonts/SpaceGrotesk-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Space Grotesk";
    src: url("../../Assets/fonts/SpaceGrotesk-Medium.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: "Space Grotesk";
    src: url("../../Assets/fonts/SpaceGrotesk-Light.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: block;
}

.privacyWrap {
    margin-top: 4.5vw;
    background-color: #000;
    height: 100%;

    .content {
        display: flex;
        flex-direction: column;
        padding-left: 3vw;
        padding-top: 4vw;
        padding-right: 5vw;

        h3 {
            font-size: 1.3vw;
            font-family: 'Space Grotesk';
            color: #fff;
            margin: 0;
            letter-spacing: 0.1vw;
        }

        p {
            color: #fff;
            font-size: 1.1vw;
            font-family: 'Space Grotesk';
            font-weight: 300;
            line-height: 1.8;

            span {
                color: #F5D42F;
                font-weight: 900;
            }
        }

        .policies {
            .points {
                color: #fff;
                font-family: 'Space Grotesk';
                font-size: 1vw;
                letter-spacing: 0.1vw;
                font-weight: 100;
                margin-bottom: 1vw;

                span {
                    color: #F5D42F;
                    font-weight: 900;
                }
            }
        }

        .modal {
            background: #fff;
            width: 30vw;
            border-radius: 0.4vw;
            padding: 3vw;
            height: 25vw;
            .title {
                color: #000;
                margin-bottom: 1.4vw;
                font-weight: 400;
            }
            .formStatus {
                display: flex;
                flex-direction: column;
                align-items: center;
          
                svg {
                  font-size: 7vw;
                  color: #F5D42F;
                }
          
                svg.error {
                  color: #d50000;
                }
          
                .message {
                  margin-top: 2vw;
                  color: #000;
                }
              }
          
        }
        .status{
            display: flex;
    align-items: center;
    justify-content: center;
        }
    }


    .delContent {
        div[class^="MuiGrid-root MuiGrid-item"] {
            p[class^="MuiFormHelperText-root Mui-error"] {
                font-size: 0.8vw;
                font-family: 'Space Grotesk';
                margin: 0;
            }
        }

        align-items: center;

        p {
            color: #c70000;
            font-size: 0.8vw;
            font-family: 'Space Grotesk';
            font-weight: 400;
            margin-left: 0 !important;
        }

        label {
            font-family: 'Space Grotesk';
            font-size: 1.2vw;
        }

        .confirmPswd {
            div {
                border-radius: 0;

                input {
                    padding: 1.2vw 1.1vw;
                    font-family: "Space Grotesk";
                    font-size: 1.1vw;
                }
            }

            label {
                font-family: 'Space Grotesk';
                font-size: 1.2vw;
            }

            p[class^="MuiFormHelperText-root Mui-error"] {
                font-size: 0.8vw;
                font-family: 'Space Grotesk';
                margin: 0;
            }

        }
    }

    .delBtn {
        background-color: #000;
        background: lighten($color: #000, $amount: 3%);
        border: 0.1vw solid #000;
        color: #F5D42F !important;
        font-size: 1vw;
        font-weight: 600;
        font-family: 'Space Grotesk';
        width: 100%;
        height: 3.5vw;
        letter-spacing: 0.2vw;
        margin-top: 1.5vw;

        &:hover {
            color: #F5D42F;
            background: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.2) 55%, transparent 55%, transparent 100%),
                linear-gradient(135deg, #000 0%, #000 50%, #000 50%, #000 100%);
            box-shadow: 0 0 0.8vw #F5D42F;
            cursor: pointer;
        }
    }
}

.delWrapper {
    height: 100vh;
}

@media (max-width: 768px) {
    .privacyWrap {
        height: 100vh;
        overflow: scroll;

        .content {
            padding-top: 15vw;
            padding-left: 6vw;

            h3 {
                font-size: 3.5vw;
            }

            p {
                font-size: 3vw;
            }

            .policies {
                padding-inline-start: 1.4vw;

                .points {
                    font-size: 3vw;
                    line-height: 4vw;
                    margin-bottom: 1.5vw;
                }
            }

            .modal {
                width: 85%;
                max-width: 85%;
                height: 60vw;
                margin-top: 7vw;
                .title{
                    margin-bottom: 5vw;
                }
                .form-container{
                    .item:first-child{
                        margin-bottom: 7vw;
                        height: 8vw;
                    }
                }
            }
        }
        .delContent{
            label, .confirmPswd label{
                font-size: 3vw;
                padding-left: 4vw;
                padding-top: 1vw;
            }
            .email div, .confirmPswd div {
                height: 8vw;
                max-height: 8vw;
                input{
                    font-size: 2.8vw;
                    padding-top: 4vw;
                }
            }
            .confirmPswd div{
                input{
                    padding: 2.5vw 3vw;
                }
            }
            .MuiInputLabel-shrink {
                left: 0vw !important;
                text-align: inherit !important;
                top: -2vw !important;
            }
            div[class^="MuiGrid-root MuiGrid-item"] p[class^="MuiFormHelperText-root Mui-error"]{
              font-size: 1.5vw;  
            }
        }
        .delBtn{
            height: 7vw;
            font-size: 2.5vw;
            letter-spacing: 0.4vw;
            margin-top: 2.8vw;
        }
    }
}